<template>
    <v-dialog v-model="dialog" width=800 persistent>
        <v-card>
            

            <v-toolbar color="primary" dark dense extended>
                <v-toolbar-title>Paqueteoperes</v-toolbar-title>
                <v-spacer/>
                <v-btn text @click="cancela">
                    <v-icon>mdi-close-box</v-icon>
                </v-btn>

                <template v-slot:extension>
                    <v-spacer/>
                    <v-select
                        label="Anteriores"
                        :items="paquetes"
                        v-model="estosDatos"
                        return-object
                        @change="cargaEstePaquete"
                        item-value="id"
                        item-text="dateCreated"
                    >
                        <template v-slot:item="{ item }">
                            {{moment(item.dateCreated).format('DD/MM/YYYY HH:mm')}} por {{item.crea}}
                        </template>

                        <template v-slot:selection="{ item }">
                            {{moment(item.dateCreated).format('DD/MM/YYYY HH:mm')}} por {{item.crea}}
                        </template>
                    </v-select>
                    <v-btn icon :disabled="(!estosDatos.id>0)" @click='list("p")'>
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                </template>

            </v-toolbar>

            <v-card-text>
                <v-row class="mt-3" >
                    <v-col cols="12" sm="6" v-if="cirugia">
                        <v-text-field
                            v-model="cirugia.pacienteNombre"
                            label="Paciente"
                            dense
                            readonly
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field
                            v-model="cirugia.fechaSolicitada"
                            label="Fecha solicitada"
                            dense
                            readonly
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-text-field
                            v-model="cirugia.fechaAsignada"
                            label="Fecha Asignada"
                            dense
                            readonly
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="cirugia.procedimientoDescripcion"
                            label="Procedimiento"
                            dense
                            readonly
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                            v-model="cirugia.padecimiento"
                            label="Diagnóstico"
                            dense
                            readonly
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-form ref="formPaqueteoperes" v-model="formValid">
                    <v-row class="mt-5" no-gutters v-if="cirugia">

                        <v-col cols="12" sm="6">
                            <v-select
                                v-model="estosDatos.tipoInternamiento"
                                :items="['Hospitalización', 'Ambulatoria']"
                                label="Tipo de internamiento"
                                rounded
                                filled
                                dense
                                :rules="[v => !!v || 'Requerido.']"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <page-fecha
                                label='Fecha de internamiento'
                                :model.sync="estosDatos.fechaInternamiento"
                                :dense="true"
                                :rules="[v => !!v || 'Requerido.']"
                            ></page-fecha>
                        </v-col>

                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="estosDatos.ayunoHoras"
                                label="Horas de ayuno requeridas"
                                type="number"
                                rounded
                                filled
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="estosDatos.testigo"
                                label="Testigo"
                                rounded
                                filled
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-text-field
                                v-model="estosDatos.responsable"
                                label="Responsable"
                                rounded
                                filled
                                dense
                                :rules="[v => !!v || 'Requerido.']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" >
                            <v-text-field
                                v-model="estosDatos.condiciones"
                                label="Condiciones"
                                rounded
                                filled
                                dense
                                :rules="[v => !!v || 'Requerido.']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" >
                            <v-text-field
                                v-model="estosDatos.complicaciones"
                                label="Complicaciones"
                                rounded
                                filled
                                dense
                                :rules="[v => !!v || 'Requerido.']"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="estosDatos.solucion"
                                label="Solución requerida"
                                rounded
                                filled
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="estosDatos.medicamentos"
                                label="Medicamentos requeridos"
                                rounded
                                filled
                                dense
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" >
                            <v-textarea
                                v-model="estosDatos.valoracionPa"
                                label="Valoración pre anestésica"
                                rounded
                                filled
                                dense
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Componentes sanguineos</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col
                                        v-for="(itm, id1) in items1"
                                        :key="id1"
                                        cols="12"
                                        xs="6"
                                        sm="4"
                                    >
                                        <v-text-field
                                            v-model="estosDatos[itm.model]"
                                            :label="itm.text"
                                            rounded
                                            filled
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-form>

            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn color="success" @click="guarda" :disabled="!formValid">
                    Guardar
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    components:{
        'page-fecha':()=>import('@/components/com/SelectEditaFecha'),
    },
    props:{
        dialogModal: Boolean,
        cirugia:Object,
    },

    data:()=>({
        formValid:false,
        estosDatos:{},

        items1:[
            {text:"Hemoglonina", model:"hb"},
            {text:"Hematocrito", model:"hto"},
            {text:"Plaquetas", model:"palquetas"},
            {text:"Tiempo de protrombina", model:"tp"},
            {text:"Tiempo parcial de tromboplastina", model:"tpt"},

            {text:"Paquetes globulares", model:"pglo"},
            {text:"Plasma fesco congelado", model:"pfc"},
            {text:"Concentrado plaquetario", model:"concentradoPla"},
            {text:"Crioprecipitado", model:"crioPre"},
            {text:"Total paquetes de sangre", model:"paquetesSangre"},

        ],
        paquetes:[],
    }),

    methods:{
        cargaEstePaquete(itm){
            this.estosDatos=itm
        },
        async guarda(){
            //console.log(this.estosDatos)
            this.estosDatos.cirugia = this.cirugia.id
            try{
                let req = await this.$http({
                    url: '/cirugia/guardaPaqueteoperes',
                    method:'POST',
                    params:this.estosDatos,
                })
                if (req.data.estado){
                    //this.$emit("guardado")
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Actualizado correctamente',
                    })
                    this.list('l')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
            //console.log(this.estosDatos)
            //this.dialog=false
        },
        async list(tipo){
            let params={}
            params.tipo = tipo

            if (tipo == "p"){
                params.id = this.estosDatos.id
                let url = this.$baseUrl+'cirugia/listPaqueteoperes/?tipo=p&id='+params.id
                window.open(url,'winname','directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600')
            }
            if (tipo == "l"){
                this.estosDatos={}
                this.paquetes=[]

                params.cirugia = this.cirugia.id
                try{
                    let req = await this.$http({
                        url: '/cirugia/listPaqueteoperes',
                        method:'POST',
                        params:params,
                    })
                    this.$refs.formPaqueteoperes.reset()
                    this.paquetes = req.data
                    if (this.paquetes.length>0){
                        this.estosDatos = this.paquetes[0]
                    }

                } catch(err){
                    this.$store.dispatch('security/revisaError',err)
                }
            }

        },

        cancela(){
            this.dialog=false
        },

    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (!v){
                this.$refs.formPaqueteoperes.reset()
            }
            if (v){
                this.list('l')
            }
        }
    },
}
</script>

<style>

</style>